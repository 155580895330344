import { FC } from 'react';
import { ParagraphFormularFragment } from '../../../generated/types';
import { Box, Heading } from '@chakra-ui/react';
import { EntityMapping } from '../../../config/entity-mapping';

interface FormularProps {
  data: ParagraphFormularFragment;
}

export const Formular: FC<FormularProps> = ({ data }) => {
  const Component = EntityMapping[`Formular_${data.formular}`] || null;

  if (!Component) return null;

  return (
    <Box>
      {data.headline && (
        <Heading fontSize="2xl" mb={4} fontWeight={500} as="h3" color="primary">
          {data.headline}
        </Heading>
      )}

      <Component />
    </Box>
  );
};
